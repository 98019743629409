@font-face {
  font-family: 'Minecraft';
  src: local('Minecrafz'), url('./fonts/Minecraft.ttf') format('truetype');
}

@font-face {
  font-family: '8-bit';
  src: local('8-bit'), url('./fonts/8-bit.ttf') format('truetype');
}

button {
  cursor: url("./img/shell_cursor.png"), auto;
}

.custom-cursor {
  cursor: url("./img/shell_cursor.png"), auto;
}

body {
  font-family: '8-bit', sans-serif;
  margin: 0px auto;
  max-width: 1500px;
  background-color: #add8e6;
  color: white;
  
}

body:hover {
  cursor: url("./img/shell_cursor.png"), auto;
}

a {
  cursor: url("./img/shell_cursor.png"), auto;
}

.app {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.main {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.home {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.text-section {
  font-size: 50px;
  padding: 40px;
  margin-top: auto;
}

.image-section{
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.image-section img {
  height: 300px;
}

.mirrored-image {
  transform: scaleX(-1);
  margin-right: 50px;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 40px;
}

.cards_item {
  display: flex;
  width: 20%;
  box-sizing: border-box;

}

.card:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  /* cursor: pointer; */
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  font-size: 24px;
}

.card span {
  font-size: 2rem;
  color: black;
 
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
  font-size: 8rem;
  align-self: center;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.header-title {
  font-size: 4rem;
  color: white;
  text-decoration: none;
}

header a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: white;
}

.socials {
  margin-left: 1rem;
  display: flex;
}

nav .socials a {
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.5rem;
}

nav a:hover {
  color: #ff4901;
}

nav .socials a:hover {
  /* filter: invert(89%) sepia(11%) saturate(1283%) hue-rotate(280deg) brightness(103%) contrast(98%); */
}

nav .selected-nav {
  color: var(--primaryColor);
}

header .socials a img {
  width: 70px;
  transition: transform 0.1s ease-in-out;
}

header .socials a:hover img {
  transform: scale(1.1);
}


.nav-btn-container {
  display: none;
}

.img-logo img {
  width: 600px;
}

.img-logo {
  display: flex;
 
}

.music-player-component {
  align-self: flex-end;
}

.music-player-component img {
  width: 80px;
  
}

.disabled-link {
  color: rgb(218, 218, 218);
  cursor: none;
}

.disabled-link:hover {
  color: rgb(218, 218, 218);
}

.popup {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: #ff4901;
  box-shadow:   -15px 0 0 0 black,
  15px 0 0 0 black,
  0 -15px 0 0 black,
  0 15px 0 0 black;
  padding: 40px;
  height: fit-content;
}

.popup_link_button {
  width: 80px;
  height: 80px;
  background: none;
  border: none;
}

.popup_link_button img {
  width: 80px;
  height: 80px;
}

.popup_inner h1 {
  font-size: 3rem;
  margin-bottom: 0px;
}

.detail-image-container img {
  width: 100%;
  object-fit: contain;
}

.popup_footer {
  display: flex;
  justify-content: space-between;
}

.detail-container {
  display: flex;
  padding: 40px;
  gap: 40px;
}

.popup-container {
  display: flex;
  flex-direction: column;
}

.popup-rank {
  font-size: 2rem;
  color:aquamarine;
}

.detail-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.download-btn {
  font-size: 20px;
  font-family: 'Minecraft', sans-serif;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: end;
    align-items: center;
    margin-top: 15px;
}

.download-btn:hover {
  cursor: pointer;
}

.download-btn img {
  width: 50px;
  filter: invert(96%) sepia(42%) saturate(5%) hue-rotate(233deg) brightness(108%) contrast(100%);
}

.trait_container {
  display: flex;
  flex-direction: column;
  
}

.detail-item-value-container {
  font-size: 1.5rem;
}

.collection-selection button:hover {
  cursor: url("./img/shell_cursor.png"), auto;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.collection-selection-button {
  background:rgba(0,0,0, 0.0);
  border: none

}

.grey-image {
  filter: grayscale(100%);
}

.collection-selection-button img {
  width: 50px;
}
.custom-btn {
  /* box-shadow:   -15px 0 0 0 black,
  15px 0 0 0 black,
  0 -15px 0 0 black,
  0 15px 0 0 black; */
  padding: 10px;
  min-width: 100px;
  font-family: 'Minecraft', sans-serif;
  font-size: 2rem;
  /* cursor: pointer; */

}

.custom-btn:hover {
  background-color: grey;
}

.detail-items-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 20px;
  align-self: center;
}

.detail-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  justify-content: center;
  
}

.detail-key {
  font-weight: bold;
  color: darkturquoise;
}

.detail-item-value-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-background video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1600px) {
  .detail-items-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1200px) {
  .app {
    height: auto;
  }

  .custom-btn {
    font-size: 1.5rem;
  }

  /* .text-section {
    font-size: ;
  } */

  .detail-items-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    margin-bottom: 20px;
  }

  .detail-item {
    padding: 0px;
  }

  .popup_inner {
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .detail-image-container img {
    width: 100%;
  }

  .download-btn img {
    width: 40px;
  }

  .image-section{
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
  
  .image-section img {
    height: 200px;
  }

  .no-mobile {
    display: none;
  }

  header {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  nav {
    flex-direction: column;
    gap: 10px;
  }

  .socials {
    justify-content: center;
  }

  .img-logo img {
    width: 300px;
    align-self: center;
  }

  

  .card {
    font-size: 12px;
  }

} 

@media only screen and (max-width: 900px) {
  .detail-items-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .popup_inner {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow:  none;
    height: 100vh;
  }

  nav a {
    font-size: 4rem;
  }

  .detail-items-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .download-btn {
    margin-top: 0px;
  }

  .detail-container {
    margin-bottom: 0px;
    gap: 10px;
  }

  .detail-image-container img {
    width: 60%;
}

.popup_link_button {
  width: 60px;
  height: 60px;
}

.popup_link_button img {
  width: 60px;
  height: 60px;
}

.card span {
  font-size: 1rem;

 
}

.download-btn img {
  width: 40px;
}

}
